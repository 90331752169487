:root {
	--niki-white: #fffffa;
}

.fade-out-X {
	visibility: hidden;
	opacity: 0;
	background-color: var(--niki-white);
	animation: fadeOut 4s ease-in-out;
	z-index: 1;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
		visibility: visible;
		color: white;
  	}
	20% {
		opacity: 1;
		visibility: visible;
		color: black;
	}
	50% {
		opacity: 1;
		visibility: visible;
		color: black;
	}
	80% {
		color: gray;
	}
	100% {
		opacity: 0;
		visibility: hidden;
	}
}

.fade-in {
	animation: fadeIn 3s ease-in;
	opacity: 1;
	visibility: visible;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		visibility: hidden;
	}
	50% {
		opacity: 0;
		visibility: hidden;
	}
	100% {
		opacity: 1;
		visibility: visible;
	}
}

.niki-name {
	font-size: 60px;
}

@media only screen and (max-width: 768px){
	.niki-name {
		font-size: 40px;
	}
}

/* #backgroundImageSlider {
	opacity: 0.7;
} */

.centered {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.maxi {
	width: 100%;
	height: 100%;
	position: fixed;
}
