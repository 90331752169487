.divider {
	margin-top: 100px;
}

.text-content {
	font-family: "Fact", sans-serif;
	letter-spacing: 0.08em; 
	line-height: 2.2em; 
	font-size: 12px;
	font-weight: 400;
	color: #333;
}

.image-scroll {
    margin-top: 4.25em;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    padding: 0;	
}

.media {
	vertical-align: top;
    margin: 1.5em 2.5em 2em 0;
    display: inline-block;
    position: relative;
}

.media:first-of-type {
    margin-left: 2.5em;
	margin-bottom: 0.2em;
}

.media-content {
    height: calc(100vh - 9.5em);
}

@media only screen and (max-width: 649px){
	.media {
		margin-top: 0em;
	}

	.media-content {
    	height: calc(100vh - 8.1em);
	}
}

@media only screen and (max-width: 810px){
	.media:first-of-type {
		margin-left: 1em;
	}
}

.media-content-text {
    max-height: 200px;
}

 .media-copy {
	 position: fixed;
	 top: calc(100% - 5.5em);
	 left: 3.5em;
 }

 .media:first-of-type::after {
	content: attr(data-copy);
	font-family: "Fact", sans-serif;
	letter-spacing: 0.08em; 
	line-height: 6.4em; 
	font-size: 10px;
	font-weight: 400;
	color: #333;
}

.load-img {
	animation: loadImage 0.75s ease-in;
  	opacity: 1;    
    visibility: visible;
}

@keyframes loadImage {
  0% {
  	opacity: 0;
    visibility: hidden;
  }
  100% {
 	opacity: 1;
    visibility: visible;
  }
}

body {
  overflow-y: visible !important;
}

h1 {
	color: black;
	font-family: "Fact", sans-serif;
}

#header:has(+ .container-xxl), #header:has(+ .project-layout) {
	position: static !important;
}

.container-fluid {
	max-width: 1800px;
}

.flex-layout {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

a .project-caption, a .project-caption-2 {
	color: rgb(10, 10, 10);
	letter-spacing: .1em;
}

a .project-caption {
	font-size: 12px;
}

a .project-caption-2 {
	font-size: 10px;
}

.row {
	--bs-gutter-x: 1rem;
}

.container-fluid {

}


@media only screen and (min-width: 576px){ /* xs */
	/* .row {
		--bs-gutter-x: 1rem;
	} */

	.flex-layout {
		gap: 1rem;
	}
}


@media only screen and (min-width: 992px){ /* md */
	/* .row {
		--bs-gutter-x: 1rem;
	} */

	a .project-caption {
		font-size: 12px;
	}

	a .project-caption-2 {
		font-size: 10px;
	}

	.flex-layout {
		gap: 1rem;
	}
}

@media only screen and (min-width: 1200px){ 
	.row {
		--bs-gutter-x: 1.5rem;
		margin-left: -1rem;
		margin-right: -1rem;
	}

	.flex-layout {
		gap: 1.5rem;
	}
}
