.nav-top:first-of-type {
	width: 200px;
}

.nav-top {
	line-height: 20px;
}

#header {
	height: 118px;
	z-index: 400;
}

#mobile-menu-label, #mobile-menu {
	letter-spacing: .25em;
}

#header div#logo {
	letter-spacing: .017em;
	font-size: 24px;
}

@media only screen and (min-width: 768px){ /* sm */
	#header div#logo {
		margin-top: -12px;
	}
}

#header.fair div#logo, .fair a, .fair button {
	color: black;	
}

.hamburger-menu {
	height: 40px;
	width: 40px;
}

.hamburger {
	width: 19px;
	height: 1px;	
	background-color: white;
	left: 10px;
	transition: transform .25s ease-out, opacity .25s;
}

.fair .hamburger, .mobile-menu-show .hamburger {
	background-color: black;
}

.hamburger-top {
	top: 13px;
}

.open .hamburger-top {
	transform: translateY(6px) rotate(45deg);
}

.hamburger-bottom {
	top: 25px;
}

.open .hamburger-bottom {
	transform: translateY(-6px) rotate(-45deg);
}


#menu li {
	font-size: 12px;
}

#menu, #mobile-menu-links {
  letter-spacing: .2em;
}

/* mobile menu */

#mobile-menu {
	max-width: 100px;
}

#mobile-backdrop {
	z-index: 390;
	background: rgba(255,255,250,.9);	
}

.mobile-menu-show a, .mobile-menu-show button {
	color: black;
}

.mobile-menu-show a:hover, .mobile-menu-show button:hover {
	color: #999;
}

#mobile-menu-links {
	z-index: 400;
	transform: translateX(-50%) translateY(-50%);	
}
