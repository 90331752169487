a.ext-link {
	color: #333;
	text-decoration: underline;
}

a.ext-link:hover {
	color: #999;
}

.profile-container {
  	margin-top: 120px;
}

.profile-content {
	background-color: var(--niki-white);
	font-family: "Fact", sans-serif;
	letter-spacing: 0.08em; 
	line-height: 2.2em; 
	font-size: 11px;
	font-weight: 400;
	color: #333;
	max-width: 600px;
  	width: calc(100% - 120px);
}

@media only screen and (max-width: 768px){
	.profile-content {
		width: calc(100% - 40px);
		font-size: 11px;
	}
}

@media only screen and (max-width: 576px){
	.profile-container {
		margin-top: 40px;
	}
}
