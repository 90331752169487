@font-face {
	font-family: "Freight";  
	src: url('fonts/FreightSansBookSC.otf') format('opentype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Fact";  
	src: url('fonts/FaktProNormal.otf') format('opentype');
}

@font-face {
	font-family: "Wien";  
	src: url('fonts/Wien Pro Unic Light.otf') format('opentype');
}

body {
  font-family: "Freight", Helvetica, Arial, sans-serif;
  background-color: var(--niki-white);
  margin: 0px;
  overflow-y: hidden;
}

a, button {
	color: #fafafa;
	background: transparent;
	border: 0;
	text-decoration: none;
}

a:hover, button:hover {
	color: #999;
}

ul {
	list-style: none;
}

.vienna {
  font-family: "Wien", Helvetica, Arial, sans-serif;
}

.image-container {
  width: 25%;
  margin: 40px auto;
}
